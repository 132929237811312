import type { Option } from '@/domain/base';
import type { ResponseError } from '@/services/apiClient';
import { arrayToMap, handleError } from '@/utils/utils';
import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import type { GenericAbortSignal } from 'axios';
import { useEffect, useMemo, useRef } from 'react';

type DefaultOptions = {
  errorMessage: string | undefined;
};

interface FetchHookOptions<T> {
  disabled?: boolean;
  errorMessage?: string;
  onSuccess?: (data: T | undefined) => void;
  refetchOnMount?: boolean;
}

type Result = any;
type QueryKey = any[];
type QueryByIdFn<Data, Params> = (params: Params, signal?: GenericAbortSignal) => Promise<Data>;
type QuerySearchFn<Data, Params> = (params: Params, signal?: GenericAbortSignal) => Promise<Data>;
type QueryNoParamsFn<Data> = (signal?: GenericAbortSignal) => Promise<Data>;
type QueryRegularFn<Data, Params> = (params: Params, signal?: GenericAbortSignal) => Promise<Data>;

interface OptionsSettings {
  includeNoneOption?: boolean;
}

type ParseToMapConfig<MapKey> = {
  getKey: (item: Result extends any[] ? Result[0] : Result) => MapKey;
  getValue?: (item: Result extends any[] ? Result[0] : Result) => Result[0];
};

type MapAndOptionsHookOptions<MapKey extends keyof any, OptionValue> = {
  parseToMap?: ParseToMapConfig<MapKey>;
  parseToOptions?: ParseToOptionsConfig<OptionValue>;
};

type ParseToOptionsConfig<OptionValue> = {
  getLabel: (item: Result extends any[] ? Result[0] : Result) => string;
  getValue?: (item: Result extends any[] ? Result[0] : Result) => OptionValue;
  noneOption?: Option<OptionValue>;
  sort?: boolean;
};

type SearchHookOptions<
  MapKey extends keyof any,
  OptionValue,
  Params extends object = object,
> = DefaultOptions &
  MapAndOptionsHookOptions<MapKey, OptionValue> & {
    disable?: (params: Partial<Params>) => boolean;
  };

type NoParamsHookOptions<MapKey extends keyof any, OptionValue> = DefaultOptions &
  MapAndOptionsHookOptions<MapKey, OptionValue>;

type RegularHookOptions<Params extends any[]> = DefaultOptions & {
  disable?: (params: Params) => boolean;
};

const DEFAULT_EMPTY_PARAMS = {};

const useOptionsSettings = <OptionValue = Result>(
  data: Result[] | undefined,
  config: ParseToOptionsConfig<OptionValue> | undefined,
  optionsSettings: OptionsSettings | undefined,
) => {
  const { getLabel, getValue, sort: optionsSort = true } = config || {};

  return useMemo<Option<OptionValue>[]>(() => {
    if (!getLabel || !getValue || !data) {
      return [];
    }

    const mappedOptions = data.map((item: Result) => ({
      label: getLabel(item),
      value: getValue(item),
    }));

    if (optionsSort) {
      mappedOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    }

    if (optionsSettings?.includeNoneOption && config?.noneOption) {
      mappedOptions.unshift(config.noneOption);
    }

    return mappedOptions;
  }, [data, getLabel, getValue, optionsSort, optionsSettings, config?.noneOption]);
};

const useMapSettings = <MapKey extends keyof any = keyof any>(
  data: Result | undefined,
  config: ParseToMapConfig<MapKey> | undefined,
) => {
  const { getKey: mapKey, getValue: mapValue } = config ?? {};
  return useMemo<Partial<Record<MapKey, Result[0]>>>(() => {
    if (!mapKey || !data) {
      return {};
    }

    return arrayToMap(data, mapKey, mapValue);
  }, [data, mapKey, mapValue]);
};

const isSuccess = <Data>(result: UseQueryResult<Data, Error>) =>
  result && result.isFetched && !result.isError;

const onError = (
  error: Error,
  errorMessage: string | undefined,
  options: DefaultOptions,
  signal?: AbortSignal,
) => {
  if (signal?.aborted) {
    throw error;
  }
  const message = errorMessage ?? options.errorMessage;
  handleError(error, {
    displayToast: !!message,
    toastMessage: message,
  });
  return false;
};

const useEnrichedQuery = <Data, Params>(
  queryKey: QueryKey,
  queryFn: (params: Params, signal?: AbortSignal) => Promise<Data>,
  params: Params,
  disabledParam: boolean,
  options: FetchHookOptions<Data>,
  defaultOptions: DefaultOptions,
) => {
  const { disabled, onSuccess, errorMessage } = options;

  const controllerRef = useRef<AbortSignal>();

  const currentQueryKey = [...queryKey, params];

  const result = useQuery({
    queryKey: currentQueryKey,
    queryFn: (context) => {
      controllerRef.current = context.signal;
      return queryFn(params, context.signal).then((data) => {
        if (controllerRef?.current?.aborted) {
          throw new Error('Fetch Aborted');
        }
        return data;
      });
    },
    throwOnError: (error: Error) =>
      onError(error, errorMessage, defaultOptions, controllerRef?.current),
    enabled: !disabled && !disabledParam,
    refetchOnMount: options?.refetchOnMount,
    retry: (failureCount, error: Error) => {
      if ((error as ResponseError)?.status === 404) {
        return false;
      }
      return failureCount < 4;
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isSuccess(result)) {
      onSuccess?.(result.data);
    }
  }, [result, onSuccess]);

  return result;
};

/**
 * **This function must be used only when creating the hook that will be used in the components**
 *
 * Used for hooks that require only an ID as parameter
 *
 * @example
 * ```typescript
 * // In a file like `src/hooks/services/brands.ts` do:
 * const useBrand = HookBuilderQuery.getByIdHook(BrandsService.getById, {
 *   errorMessage: 'There was an error fetching the brand',
 * });
 *
 * // Then in the component we use it like this:
 * const { data: brand } = useBrand(id);
 * // Or:
 * const { data: brand } = useBrand(id, {
 *   // Custom options here...
 *   errorMessage: 'Custom error message',
 * });
 * ```
 */
const getByIdHook = <Data, Params>(
  queryKey: QueryKey,
  queryFn: QueryByIdFn<Data, Params>,
  defaultOptions: DefaultOptions,
) => {
  return (
    id?: number | number[],
    fetchOptions: FetchHookOptions<Result> = DEFAULT_EMPTY_PARAMS,
  ) => {
    const params = Array.isArray(id) ? id : id ?? 0;
    const idMissing = Array.isArray(params) ? params.some((i) => !i) : !id;

    // TODO: Improve "params" variable type
    // @ts-ignore
    return useEnrichedQuery(queryKey, queryFn, params, idMissing, fetchOptions, defaultOptions);
  };
};

/**
 * **This function must be used only when creating the hook that will be used in the components**
 *
 * @example
 * ```typescript
 * // In a file like `src/hooks/services/brands.ts` do:
 * const useBrand = HookBuilderQuery.regularHook(BrandsService.getById, {
 *   errorMessage: 'There was an error fetching the brand',
 * });
 *
 * // Then in the component we use it like this:
 * const { data: brand } = useBrand([id]);
 * // Or:
 * const { data: brand } = useBrand([id], {
 *   // Custom options here...
 *   errorMessage: 'Custom error message',
 * });
 * ```
 */
const regularHook = <Data extends Result, Params extends any[] = any[]>(
  queryKey: QueryKey,
  queryFn: QueryRegularFn<Data, Params>,
  defaultOptions: RegularHookOptions<Params>,
) => {
  // If we didn't have optional parameters in hooks we could use something like this:
  // `type Args = [...Params, FetchHookOptions<Result>?];`
  // but since we cannot know if optional parameters are passed or not
  // (and same happens with `options`) we can't use this approach

  return (params: Params, fetchOptions: FetchHookOptions<Result> = DEFAULT_EMPTY_PARAMS) => {
    const disabledByParams = useMemo(() => {
      if (!defaultOptions.disable) {
        return false;
      }

      return defaultOptions.disable(params);
    }, [params]);

    return useEnrichedQuery(
      queryKey,
      queryFn,
      params,
      disabledByParams,
      fetchOptions,
      defaultOptions,
    );
  };
};

/**
 * **This function must be used only when creating the hook that will be used in the components**
 *
 * Used for hooks that don't require any parameters
 *
 * @example
 * ```typescript
 * // In a file like `src/hooks/services/brands.ts` do:
 * const useBrands = HookBuilderQuery.noParamsHook(BrandsService.search, {
 *   errorMessage: 'There was an error fetching the brands',
 * });
 *
 * // Then in the component we use it like this:
 * const { data: brands } = useBrands();
 * // Or:
 * const { data: brands } = useBrands({
 *   // Custom options here...
 *   errorMessage: 'Custom error message',
 * });
 * ```
 */
const noParamsHook = <Data extends Result, MapKey extends keyof any = keyof any, OptionValue = any>(
  queryKey: QueryKey,
  queryFn: QueryNoParamsFn<Data>,
  defaultOptions: NoParamsHookOptions<MapKey, OptionValue>,
) => {
  return (
    fetchOptions: FetchHookOptions<Result> = DEFAULT_EMPTY_PARAMS,
    optionsSettings: OptionsSettings = DEFAULT_EMPTY_PARAMS,
  ) => {
    const result = useEnrichedQuery(
      queryKey,
      (_, signal?: AbortSignal) => queryFn(signal),
      undefined,
      false,
      fetchOptions,
      defaultOptions,
    );

    const arrayData = Array.isArray(result.data) ? result.data : undefined;

    // Build `map` property
    const map = useMapSettings(arrayData, defaultOptions.parseToMap);

    // Build `options` property
    const optionsArray = useOptionsSettings(
      arrayData,
      defaultOptions.parseToOptions,
      optionsSettings,
    );

    return { ...result, map, options: optionsArray };
  };
};

/**
 * **This function must be used only when creating the hook that will be used in the components**
 *
 * Used for hooks that require search parameters
 *
 * @example
 * ```typescript
 * // In a file like `src/hooks/services/user.ts` do:
 * const useUsers = HookBuilderQuery.searchParamsHook(UserService.search, {
 *   errorMessage: 'There was an error fetching the users',
 * });
 *
 * // Then in the component we use it like this:
 * const { data: users } = useUsers({ status });
 * // Or:
 * const { data: users } = useUsers({ status }, {
 *   // Custom options here...
 *   errorMessage: 'Custom error message',
 * });
 * ```
 */
const searchParamsHook = <
  Data extends Result,
  Params extends object = object,
  MapKey extends keyof any = keyof any,
  OptionValue = any,
>(
  queryKey: QueryKey,
  queryFn: QuerySearchFn<Data[], Params>,
  defaultOptions: SearchHookOptions<MapKey, OptionValue>,
) => {
  return (
    params = DEFAULT_EMPTY_PARAMS as Params,
    fetchOptions: FetchHookOptions<Result> = DEFAULT_EMPTY_PARAMS,
    optionsSettings: OptionsSettings = DEFAULT_EMPTY_PARAMS,
  ) => {
    const disabledByParams = useMemo(() => {
      if (!defaultOptions.disable) {
        return false;
      }

      return defaultOptions.disable(params);
    }, [params]);

    const result = useEnrichedQuery(
      queryKey,
      queryFn,
      params,
      disabledByParams,
      fetchOptions,
      defaultOptions,
    );

    // Build `map` property
    const map = useMapSettings(result.data, defaultOptions.parseToMap);

    // Build `options` property
    const optionsArray = useOptionsSettings(
      result.data,
      defaultOptions.parseToOptions,
      optionsSettings,
    );

    return { ...result, map, options: optionsArray };
  };
};

const HookBuilderQuery = {
  getByIdHook,
  searchParamsHook,
  regularHook,
  noParamsHook,
};

export default HookBuilderQuery;
