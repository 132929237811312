import type { New, PropifyEntity } from '@/domain/base';
import { EntityType } from '@/domain/entity-type';
import type { File } from '@/domain/file';
import type { JobType } from '@/domain/job';
import type { MonetaryAmount } from '@propify/components';

export enum InvoiceStatus {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  DENIED = 'DENIED',
  APPROVED_BY_1 = 'APPROVED_BY_1',
  APPROVED_BY_2 = 'APPROVED_BY_2',
  FULLY_APPROVED = 'FULLY_APPROVED',
  PAYABLE_CREATED = 'PAYABLE_CREATED',
  VOID = 'VOID',
}

export interface Invoice extends PropifyEntity {
  payeePartyId: number;
  externalId: string;
  status: InvoiceStatus;
  amount: MonetaryAmount;
  firstApprovalTime?: string;
  firstApprovalLoginId?: number;
  secondApprovalTime?: string;
  secondApprovalLoginId?: number;
  payableId?: string;
  glCode?: string;
  attributions?: InvoiceAttributionSearchResult[];
}

export type InvoiceAttributionEntityType = Extract<EntityType, EntityType.WORK_ORDER>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceAttributionEntityType = {
  [EntityType.WORK_ORDER]: EntityType.WORK_ORDER,
} as const;

export interface InvoiceAttribution extends PropifyEntity {
  entity: InvoiceAttributionEntity;
}
export interface InvoiceAttributionSearchResult extends InvoiceAttribution {
  invoiceCount: number;
  amount: MonetaryAmount;
}

export interface InvoiceSearchResult extends Invoice {
  file?: File;
}

export interface InvoiceCreateRequest {
  payeePartyId: number;
  externalId: string;
  amount: number;
  attributions?: InvoiceAttributionBody[];
}

export interface InvoiceSearchParams {
  entityType?: InvoiceAttributionEntityType;
  entityId?: number;
  status?: InvoiceStatus[] | InvoiceStatus;
  payeePartyId?: number;
}

export type InvoiceAttributionBody = {
  entity: New<InvoiceAttributionEntity>;
};

export interface InvoicePayableDetail extends PropifyEntity {
  invoiceId: number;
  invoiceAccountId?: number | null;
  invoiceAttributionEntityId: number;
  invoiceAttributionEntity: InvoiceAttributionEntity;
  amount: MonetaryAmount;
  notes: string;
}

export interface InvoiceAccount extends PropifyEntity {
  number: string;
  description: string;
  usageDescription?: string;
  naturalDisasterRecovery?: boolean;
  recurringService?: boolean;
  jobTypes?: (JobType | null)[];
  ventureIds?: number[];
  payableDetailMinAmount?: MonetaryAmount;
  payableDetailMaxAmount?: MonetaryAmount;
  invoiceTotalMinAmount?: MonetaryAmount;
  invoiceTotalMaxAmount?: MonetaryAmount;
  jobTotalMinAmount?: MonetaryAmount;
  jobTotalMaxAmount?: MonetaryAmount;
}

export interface InvoicePayable extends PropifyEntity {
  invoiceId: number;
  invoicePayableDetailsIds: number[];
  foreignDbId: string;
  fundingEntityId: string;
  amount: MonetaryAmount;
  date: string;
}

export interface InvoiceAttributionEntity extends PropifyEntity {
  entityType: InvoiceAttributionEntityType;
  entityId: number;
  unitId: number;
  fullyInvoiced: boolean;
}

export interface InvoiceAccountSearchParams {
  id?: number[];
  number?: string[];
  acceptNaturalDisasterRecovery?: boolean;
  acceptRecurringService?: boolean;
  payableDetailAmount?: MonetaryAmount;
  invoiceTotalAmount?: MonetaryAmount;
}

export interface InvoicePayableDetailBulkUpdateRequest {
  ids: number[];
  invoiceAccountId?: number;
  invoiceAttributionEntityId?: number;
  amount?: MonetaryAmount;
  notes?: string;
}
