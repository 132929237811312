import HookBuilderQuery from '@/hooks/HookBuilderQuery';
import { UserService } from '@/services/user';

export const useUserPropertyGroups = HookBuilderQuery.getByIdHook(
  ['users', 'user-property-groups'],
  UserService.findPropertyGroups,
  {
    errorMessage: 'There was an error fetching the property groups',
  },
);

export const useUsers = HookBuilderQuery.searchParamsHook(['users'], UserService.search, {
  errorMessage: 'There was an error while loading the users',
});

export const useUser = HookBuilderQuery.getByIdHook(['users'], UserService.findById, {
  errorMessage: 'There was an error fetching the user',
});

export const useMe = HookBuilderQuery.noParamsHook(['users', 'users-me'], UserService.me, {
  errorMessage: 'There was an error fetching the logged user information',
});

export const useCallCenterAgent = HookBuilderQuery.getByIdHook(
  ['users', 'users-agents'],
  UserService.findCallCenterAgent,
  {
    errorMessage: 'There was an error fetching the call center agent',
  },
);
