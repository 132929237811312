import { logout } from '@/utils/authority';
import { getValidAccessToken } from '@/utils/refreshToken';
import message from 'antd/es/message';
import type { AxiosResponse } from 'axios';
import Axios from 'axios';
import queryString from 'query-string';

export const apiClient = Axios.create({
  baseURL: '/rest',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

export class ResponseError extends Error {
  response?: AxiosResponse;

  jsonResponse: any;

  textResponse: string;

  displayMessage: string;

  status?: number;

  constructor(msg: string, response?: AxiosResponse) {
    super(msg);
    this.response = response;
    this.jsonResponse = {};
    this.textResponse = '';
    this.displayMessage = msg;
  }
}

apiClient.interceptors.request.use((config) => {
  // No extra configuration for absolute URLs (i.e.: a file in S3)
  if (config.url!.charAt(0) !== '/') {
    return config;
  }

  if (config.url === '/auth/refresh') {
    return config;
  }

  return getValidAccessToken()
    .then((accessToken) => {
      // eslint-disable-next-line no-param-reassign
      config.headers = config.headers ?? {};
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    })
    .catch((error: string) => {
      return Promise.reject(new Axios.Cancel(error));
    });
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    let errorText = `Unhandled status: ${error.response?.status}`;

    if (error.response?.status === 401) {
      logout();
      errorText = 'The username or password you entered is incorrect.';
    }
    if (error.response?.status === 500) {
      errorText = 'Internal Server Error';
    }

    if (error.response?.status === 400) {
      errorText = 'Bad Request';
    }

    if (error.response?.status === 403) {
      errorText = 'Missing permission';
    }

    if (error.response?.status === 504) {
      errorText = 'Gateway timeout';
      message.error({
        content: 'Error communicating with remote server. Please check your internet connection.',
        // cspell:ignore NETGONE
        key: 'NETGONE',
      });
    }

    const responseError = new ResponseError(errorText, error.response);

    responseError.status = error.response?.status;
    if (error.response?.data instanceof Object) {
      responseError.jsonResponse = error.response!.data;
      if (responseError.jsonResponse.message) {
        responseError.displayMessage = responseError.jsonResponse.message;
      } else if (responseError.jsonResponse.errors) {
        [responseError.displayMessage] = responseError.jsonResponse.errors;
      }
    } else {
      responseError.textResponse = error.response?.data as string;
    }
    throw responseError;
  },
);
