import type { Permission } from '@/domain/permissions';
import type { PartyRoleType } from '@/domain/person';
import type { User } from '@/domain/user';
import { redirectToAuthClient } from '@/utils/redirectToAuthClient';
import { handleError, setItemInLocalStorageAndSendEvent } from '@/utils/utils';
import memoize from 'lodash/memoize';
import uniq from 'lodash/uniq';

export const LOGOUT_MESSAGE_CHANNEL = 'logout_channel';
export const LOGOUT_EVENT_NAME = 'logout_event';
const LOGOUT_CHANNEL = new BroadcastChannel(LOGOUT_MESSAGE_CHANNEL);

const clearCache = () => {
  LOGOUT_CHANNEL.postMessage(LOGOUT_EVENT_NAME);
};

export function setAccessToken(token: string): void {
  setItemInLocalStorageAndSendEvent('access_token', token);
}

export function setRefreshToken(token: string): void {
  setItemInLocalStorageAndSendEvent('refresh_token', token);
}

export const getAccessToken = () => localStorage.getItem('access_token');

export const getRefreshToken = () => localStorage.getItem('refresh_token');

export const setPermissions = (permissions: Permission[]) => {
  setItemInLocalStorageAndSendEvent('permissions', JSON.stringify(permissions));
};

export const getPermissions = memoize(
  () => {
    const value = localStorage.getItem('permissions');

    if (!value) {
      return undefined;
    }

    return JSON.parse(value) as Permission[];
  },
  () => localStorage.getItem('permissions'),
);

export const getPermissionsFromUser = (user: User) => {
  const permissions = user.roles.map((role) => role.type.permissions).flat();
  return uniq(permissions).sort();
};

export const loggedIn = () => !!getRefreshToken();

export const logout = (): void => {
  clearCache();
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('permissions');
  redirectToAuthClient();
};

export const hasPermissions = (
  key: Permission | Permission[],
  permissions = getPermissions() ?? [],
) =>
  typeof key === 'string'
    ? !!permissions?.includes(key)
    : !!key?.every((permission: Permission) => permissions?.includes(permission));

type ParsedJwt = {
  exp: number;
  organizationRoleTypes: PartyRoleType[];
  permissions: Permissions[];
  orgId: number;
  userId: number;
  loginId: number;
  trackId: number;
};

export const parseJwt = memoize(
  (token: string): ParsedJwt => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );

    return JSON.parse(jsonPayload);
  },
  (token) => token,
);

export const isValidJwt = (jwt: string, jwtExpiryThreshold: number = 0) => {
  try {
    if (!jwt) {
      return false;
    }
    const parsedJwt = parseJwt(jwt);
    if (!parsedJwt) {
      return false;
    }
    return Date.now() < parsedJwt.exp * 1000 - jwtExpiryThreshold;
  } catch (error) {
    handleError(error, { displayToast: false });
    return false;
  }
};

export const getOrganizationId = memoize(
  () => {
    const accessToken = getAccessToken();
    if (!accessToken) {
      return null;
    }
    const parsed = parseJwt(accessToken);

    return parsed?.orgId ?? null;
  },
  () => getAccessToken(),
);
