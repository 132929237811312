import { apiClient } from '@/services/apiClient';
import { handleError } from '@/utils/utils';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';

export const crudService = {
  get: <T>(url: string, config?: AxiosRequestConfig) =>
    apiClient.get<T, AxiosResponse<T>>(url, config).then((response) => response.data),

  post: <T>(obj: any, url: string, config?: AxiosRequestConfig) =>
    apiClient.post<T, AxiosResponse<T>>(url, obj, config).then((response) => response.data),

  put: <T>(obj: any, url: string, config?: AxiosRequestConfig) =>
    apiClient.put<T, AxiosResponse<T>>(url, obj, config).then((response) => response.data),

  patch: <T>(obj: any, url: string, config?: AxiosRequestConfig) =>
    apiClient.patch<T, AxiosResponse<T>>(url, obj, config).then((response) => response.data),

  delete: <T>(url: string, config?: AxiosRequestConfig) =>
    apiClient.delete<T, AxiosResponse<T>>(url, config).then((response) => response.data),
};

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const retryOnFail = async <T>(
  fn: () => T | Promise<T>,
  attemptsDelays: number[],
): Promise<T> => {
  try {
    return await fn();
  } catch (error) {
    handleError(error, { displayToast: false });
    if (attemptsDelays.length > 0) {
      await delay(attemptsDelays[0]);
      return retryOnFail(fn, attemptsDelays.slice(1));
    }

    throw error;
  }
};
