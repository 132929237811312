import { Router } from '@/router';
import { LOGOUT_EVENT_NAME, LOGOUT_MESSAGE_CHANNEL } from '@/utils/authority';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect } from 'react';

const queryClient = new QueryClient();

const App = () => {
  useEffect(() => {
    const channel = new BroadcastChannel(LOGOUT_MESSAGE_CHANNEL);

    channel.onmessage = (event) => {
      if (event.data === LOGOUT_EVENT_NAME) {
        queryClient.clear();
      }
    };

    return () => {
      channel.close();
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  );
};

export default App;
